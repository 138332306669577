#personal {
    @include page-content;

    .page-content {
        @include flex-center;
        flex-direction: column;
        min-height: calc(100vh - 12rem);

        .date {
            @include font-style(1rem, $font-regular, $color-yellow);
            margin-top: 2rem;
        }
        .sub-title {
            @include font-style(1.25rem, $font-semi-bold, $color-light-blue);
            margin-top: 7rem;
        }
        .title {
            // @include font-style(1.125rem, $font-semi-bold, $color-white);
            // position: relative;
            // width: 50%;
            // margin-bottom: 1.5rem;
            // text-align: center;
            // z-index: 1;

            // &::after {
            //     content: '';
            //     position: absolute;
            //     bottom: -30%;
            //     left: 0;
            //     width: 100%;
            //     height: 70%;
            //     background: radial-gradient(50% 50% at 50% 50%, rgba(169, 132, 92, 0.6) 0%, rgba(169, 132, 92, 0) 100%);
            //     z-index: -1;
            // }

            &-1 {
                margin-top: 10rem;
                animation: title-hide 1s 1s forwards;
            }
            &-2, &-3, &-4 {
                margin-top: 7rem;
            }
            &-4 {
                margin-top: 5rem;
                margin-bottom: 5rem;
            }
            @include pad {
                margin-left: 8%;
                margin-right: 8%;
                width: 84%;
            }
        }
        .card-area {
            @include flex-center;
            position: relative;

            img.card-back {
                border-radius: $radius;
                width: 32%;
                overflow: hidden;
                // animation: card-opacity 1s 2s forwards;
            }
            img.stars {
                position: absolute;
                max-width: 45%;
                max-height: 80%;
                opacity: 0;
                animation: star-show 1s 2s forwards;
            }
            img.icon {
                position: absolute;
                top: 55%;
                max-width: 45%;
                max-height: 80%;
                opacity: 0;
                animation: icon-breathe 3s infinite linear;
                transition: opacity 1s;

                &.show {
                    opacity: 1;
                }
            }
            @keyframes icon-breathe {
                0% {
                    filter: brightness(0.8);
                }
                50% {
                    filter: brightness(1.2);
                }
                100% {
                    filter: brightness(0.8);
                }
            }

            @keyframes title-hide {
                100% {
                    opacity: 0;
                }
            }
            @keyframes card-opacity {
                100% {
                    opacity: .5;
                }
            }
            @keyframes star-show {
                100% {
                    opacity: 1;
                }
            }
        }
        p {
            @include font-style(1rem, $font-regular, $color-light-blue);
            width: 70%;
            margin: 0;
            min-height: 2rem;
            text-align: center;
            letter-spacing: 0.05rem;

            &.second {
                margin-top: 1rem;
            }
        }
        img.scene {
            width: 60%;
            margin-top: 2rem;
        }
        img.mythical-beast {
            width: 100%;
        }
        img.astrolabe {
            width: 75%;
            margin-bottom: 5rem;
            box-shadow: 0 0 2rem 0.5rem #000;
            border-radius: 100%;
        }

        @include pad {
            .card-area {
                img.card-back {
                    width: 60%;
                }
                img.icon {
                    max-width: 60%;
                    top: 65%;
                }
            }
            img.mythical-beast {
                width: 85%;
            }
        }
    }

    &.not-found {
        .page-content {
            padding: 12rem 0;
        }

        .content-none {
            @include flex-center;
            flex-direction: column;
            position: absolute;
            background: radial-gradient(50% 50% at 50% 50%, #161C52 0%, rgba(34, 52, 101, 0) 100%);
            width: 40%;
            aspect-ratio: 1;
            z-index: 1;

            .title-none {
                @include font-style(2.5rem, $font-medium, $color-light-blue);
                margin-bottom: 0rem;
            }
            .sub-title-none {
                @include font-style(1rem, $font-regular, $color-light-blue);
            }
            .third-title-none {
                @include font-style(1.25rem, $font-regular, $color-light-blue);
            }
        }
        img.card-back {
            border-radius: $radius;
            width: 50%;
            opacity: .5;
            overflow: hidden;
        }

        @include pad {
            .content-none {
                width: 80%;
            }
        }
    }
}