$color-white: #FFF;
$color-black: #000;
$color-dark-gray: #191919;
$color-yellow: #EDBA6D;
$color-gold: #A9845C;
$color-dark-blue: #222245;
$color-light-blue: #CACCF8;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;

$radius: 8px;


@mixin mobile {
    @media (max-width: 414px) {
        @content;
    }
}
@mixin pad {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin content {
    color: $color-white;
    font-size: 0.875rem;
    font-weight: $font-regular;

    p {
        margin: 0;
        min-height: 1.7rem;
        letter-spacing: 0.04rem;
    }
    ul {
        padding: 0 1.3rem;
        li {
            font-size: 0.875rem;
        }
    }
}

@mixin page-content {
    @include flex-center;
    width: 100%;

    .page-content {
        width: 63%;
        height: 100%;
        min-width: 800px;
        margin: 0 auto;

        @include pad {
            width: 100%;
            min-width: unset;
        }
    }
}
@mixin font-style($size, $weight, $color) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
}
@mixin title {
    @include flex-center;
    @include font-style(1.125rem, $font-semi-bold, $color-yellow);
    width: 100%;
    white-space: nowrap;
    margin-bottom: 3.5rem;

    &::after, &::before {
        content: '';
        width: 100%;
        display: block;
        border: 1px solid $color-yellow;
    }
    &::before {
        margin-right: 2.3rem;
    }
    &::after {
        margin-left: 2.3rem;
    }

    @include pad {
        margin-bottom: 2rem;
    }
}

html, body, #app {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: 16px;
    background-color: $color-black;
    color: $color-white;
    font-family: 'Noto Sans TC', sans-serif;
    -webkit-tap-highlight-color: transparent;
    overflow-x: clip;
    
    @include mobile {
        font-size: 12px;
    }
    @include pad {
        font-size: 14px;
    }
    .title {
        @include title;
    }
    .sub-title {
        @include font-style(1.75rem, $font-semi-bold, $color-light-blue);
        margin-bottom: 1rem;
    }
    .list-title {
        @include font-style(1.125rem, $font-regular, $color-yellow);
        margin-bottom: 1rem;
    }
    button {
        font-family: 'Noto Sans TC', sans-serif;
        border: none;
        background: none;
        cursor: pointer;
    }
    p {
        font-family: 'Noto Sans TC', sans-serif;
        margin: 0;
        min-height: 1.7rem;
        letter-spacing: 0.04rem;
    }
    *:focus {
        outline: none;
    }
}



.block {
    @include flex-center;
    flex-direction: column;
    position: relative;
    width: 100%;


    // for test
    // height: 1200px;
    // border-bottom: 1px solid #FFF;
    // End
}

@keyframes test {
    from { opacity: 0; }
    to { opacity: 1; }
}


@import './block/info.scss';
@import './block/intro.scss';
@import './block/traffic.scss';
@import './block/souvenir.scss';
@import './block/banner.scss';

@import './component/loading.scss';
@import './component/background.scss';
@import './component/planets.scss';
@import './component/card.scss';
@import './component/footer.scss';
@import './component/navbar.scss';

@import './page/personal.scss';