.background {
    @include flex-center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
    pointer-events: none;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(25, 25, 25, 0) 0%, #000000 97.4%);
    }
    video.bg {
        min-height: 100vh;
        transition: filter 1s;

        &.dark {
            filter: brightness(0.3);
        }

        @include mobile {
            height: 100vh;
        }   
    }

    // .line-1, .line-2 {
    //     @include flex-center;
    //     position: absolute;
    //     opacity: 0;
    //     z-index: 1;

    // }
    // .line-1 {
    //     margin-top: 40vw;
    //     // margin-top: -40vw;
    //     margin-left: -30vw;
    //     animation: line-float-1 3s linear forwards;
    // }
    // .line-2 {
    //     margin-bottom: 40vw;
    //     animation: line-float-2 3s linear forwards;
    // }

    // @keyframes line-float-1 {
    //     0% {
    //         opacity: 0;
    //         transform: translateY(-10%) rotate(20deg);
    //     }
    //     25% {
    //         opacity: 1;
    //     }
    //     50% {
    //         transform: translateY(5%) rotate(20deg);
    //     }
    //     100% {
    //         transform: translateY(0%) rotate(20deg);
    //     }
    // }
    // @keyframes line-float-2 {
    //     0% {
    //         opacity: 0;
    //         transform: translateY(-10%) rotate(20deg) scaleY(-1);
    //     }
    //     25% {
    //         opacity: 1;
    //     }
    //     50% {
    //         transform: translateY(5%) rotate(20deg) scaleY(-1);
    //     }
    //     100% {
    //         transform: translateY(0%) rotate(20deg) scaleY(-1);
    //     }
    // }
    
    img.line {
        position: absolute;
        width: 150%;
        opacity: 0;
        z-index: 1;
        transform: translateX(-25%) translateY(-50%) rotate(-15deg) scaleY(1);
        transition: opacity .3s, transform 3s ease;

        @include pad {
            display: none;
        }

        &-1 {
            opacity: 1;
            transform: translateX(10%) translateY(30%) rotate(20deg);
        }
        &-2 {
            opacity: 1;
            transform: translateX(10%) translateY(0%) rotate(0deg) scaleY(2);
        }
    }
        
    // img.line {
    //     position: absolute;
    //     width: 150%;
    //     opacity: 0;
    //     z-index: 1;
    //     animation: line-motion-1 4s infinite;

    //     &-1 {
    //         animation: line-motion-1 4s;
    //     }
    //     &-2 {
    //         animation: line-motion-2 4s;
    //     }
    // }
    // @keyframes line-motion-1 {
    //     0% {
    //         transform: translateX(-25%) translateY(-50%) rotate(-15deg);
    //         opacity: 0;
    //     }
    //     50% {
    //         opacity: 1;
    //     }
    //     100% {
    //         transform: translateX(15%) translateY(30%) rotate(20deg);
    //         opacity: 0;
    //     }
    // }

    // @keyframes line-motion-2 {
    //     0% {
    //         transform: translateX(20%) translateY(20%) rotate(-40deg) scaleY(-2);
    //         opacity: 0;
    //     }
    //     50% {
    //         opacity: 1;
    //     }
    //     100% {
    //         transform: translateX(-10%) translateY(-30%) rotate(0deg) scaleY(-1);
    //         opacity: 0;
    //     }
    // }
}