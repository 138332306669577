
.blank {
    height: calc(100vh * 0.75);

    @include pad {
        height: calc(100vh * 0.1);
    }
}
#souvenir {
    @include flex-center;
    @include page-content;
    margin-bottom: 10rem;
    
    .card-area {
        @include flex-center;
        position: relative;
        width: 68%;
        margin: auto;

        img.card {
            // position: absolute;
            width: 100%;
        }
        form {
            @include flex-center;
            position: absolute;
            font-weight: $font-regular;
            width: 100%;
            margin-top: 1.5%;

            input {
                width: 9.5rem;
                font-size: 1.125rem;
                color: $color-yellow;
                border: transparent;
                border-radius: 0;
                border-bottom: 1px solid $color-yellow;
                padding-bottom: 0.5rem;
                background: none;
                text-align: center;

                &::placeholder {
                    color: $color-yellow;
                }
                &:focus-visible, &:focus {
                    outline: none;
                }

                @include pad {
                    width: 42%;
                    padding-bottom: 0.2rem;
                    font-size: .75rem;
                }
            }
            button {
                margin-top: -0.4rem;

                img {
                    width: 1.2rem;
                }

                @include pad {
                    margin-top: 0rem;
                    padding: 0;
    
                    img {
                        width: 1rem;
                    }
                }
            }
        }
    }
    @include pad {
        .title {
            width: 84%;
            padding: 0 8%;
        }
    }
}