.Navbar {
    @include page-content;
    height: 100px;
    width: 100vw;
    position: fixed;
    z-index: 3;
    background: linear-gradient(rgba(0, 0, 0, 0.8) 50%, transparent);
    // mix-blend-mode: difference;

    .page-content {
        @include flex-center;
        justify-content: space-between;
    }

    .logo-sm {
        width: 23%;
        transform: translateY(-200%);
        transition: transform .5s;
        
        button {
            width: 100%;
            color: $color-white;

            img {
                width: 100%;
            }
        }

        &.show {
            transform: translateY(0%);
        }
    }
    .nav-item {
        button, a {
            color: $color-white;
            font-size: 0.875rem;
            font-weight: $font-regular;
            padding: 0 12px;
            cursor: pointer;

            a {
                text-decoration: none;
                color: $color-white;
                padding: 0;
            }
        }
    }
    @include pad {
        height: unset;
    }
    .mobile {
        position: relative;
        width: 100%;

        button.toggle {
            position: absolute;
            right: 7vw;
            top: calc(7vw + 0.7rem);
            width: 2.3rem;
            height: 2.3rem;
            padding: 0;
            z-index: 1;

            .lines {
                width: 100%;
                height: 2px;
                background-color: $color-white !important;
                border-radius: 1000px;

                transition: background-color .3s;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    height: 2px;
                    border-radius: 1000px;
                    background-color: $color-white;
                    transition: transform .3s;
                }

                &::before {
                    transform: translateX(-50%) translateY(calc(-50% + 0.7rem));
                }
                &::after {
                    transform: translateX(-50%) translateY(calc(-50% - 0.7rem));
                }
            }
        }
        .nav-item {
            position: relative;
            width: 100%;
            padding: 0;
            overflow: hidden;

            padding: 0px;
            height: 0px;
            background: none;
            transition: padding .5s, height .5s;

            button {
                width: 100vw;
                height: 15vw;
                overflow: hidden;
                text-align: left;
                font-size: 1.15rem;
                color: $color-light-blue;
                padding-left: 3rem;
                transition: height .2s;

                a {
                    @include font-style(1.15rem, $font-regular, $color-light-blue);
                    text-align: left;
                    padding: 0;
                }

                img.logo {
                    height: 2.25rem;
                }
            }
        }
    }

    &.mobile {
        mix-blend-mode: unset;
        align-items: flex-start;
    }
    &.toggle {
        button.toggle {
            .lines {
                background-color: transparent !important;
    
                &::before {
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                }
                &::after {
                    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                }
            }
        }
        .nav-item {
            padding: 15vw 0;
            height: 100vh;
            // background: linear-gradient(#0B152F 50%, rgba(34, 52, 101, 0));
            background: linear-gradient(#000 50%, rgba(0, 0, 0, 0));
        }
    }
}