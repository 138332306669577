.Cards {
    @include flex-center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80vw;
    margin-top: 4rem;
    height: 315px;

    @include pad {
        height: 350px;
    }
    .card {
        @include flex-center;
        position: relative;
        aspect-ratio: 139/198;
        width: 14%;
        margin: 0 2%;
        -webkit-tap-highlight-color: transparent;
        z-index: 1;
        transition: transform .3s;
        // animation: card-float 5s infinite;

        .cover {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            background-image: url('../assets/images/card-back.png');
            background-size: cover;
            border-radius: $radius;
            box-shadow: 0px 0px 50px #525496;
            cursor: pointer;
            overflow: hidden;
            transition: background-image 0s .15s linear;
        }
        css-doodle {
            @include flex-center;
            position: absolute;
            z-index: 1;
        }

        &:hover {
            transform: scale(1.1) translateY(5%);
            z-index: 5;
        }
        &.back {
            margin: 0 5.2% -10% 5.2%;
            width: 12%;
        }
        &:nth-of-type(1) {
            margin: 0 5.2% -10% 10.4%;
        }
        &:nth-of-type(4) {
            margin: 0 10.4% -10% 5.2%;
        }
        &:nth-of-type(5) {
            margin: 0 2% 0 0;
        }
        &:nth-of-type(9) {
            margin: 0 0 0 2%;
        }
        &.active {
            animation: card-float 5s infinite, card-active 0.3s forwards;
            // z-index: 100;

            &.card {
                &-1 .cover {
                    background-image: url('../assets/images/cards/1.png') !important;
                }
                &-2 .cover {
                    background-image: url('../assets/images/cards/2.png') !important;
                }
                &-3 .cover {
                    background-image: url('../assets/images/cards/3.png') !important;
                }
                &-4 .cover {
                    background-image: url('../assets/images/cards/4.png') !important;
                }
                &-5 .cover {
                    background-image: url('../assets/images/cards/5.png') !important;
                }
                &-6 .cover {
                    background-image: url('../assets/images/cards/6.png') !important;
                }
                &-7 .cover {
                    background-image: url('../assets/images/cards/7.png') !important;
                }
                &-8 .cover {
                    background-image: url('../assets/images/cards/8.png') !important;
                }
                &-9 .cover {
                    background-image: url('../assets/images/cards/9.png') !important;
                }
            }
        }
        &.default {
            .cover {
                background-image: url('../assets/images/card-back.png');
            }
            // animation: card-float 5s infinite, card-default 0.3s;
            
            &.card {
                &-1 {
                    animation: card-float 5s -1s infinite, card-default 0.3s;
                }
                &-2 {
                    animation: card-float 5s -2.5s infinite, card-default 0.3s;
                }
                &-3 {
                    animation: card-float 5s -4.5s infinite, card-default 0.3s;
                }
                &-4 {
                    animation: card-float 5s -4s infinite, card-default 0.3s;
                }
                &-5 {
                    animation: card-float 5s -2s infinite, card-default 0.3s;
                }
                &-6 {
                    animation: card-float 5s -1.5s infinite, card-default 0.3s;
                }
                &-7 {
                    animation: card-float 5s -3.5s infinite, card-default 0.3s;
                }
                &-8 {
                    animation: card-float 5s -0.5s infinite, card-default 0.3s;
                }
                &-9 {
                    animation: card-float 5s -3s infinite, card-default 0.3s;
                }
            } 
        }
        &.random, &.mobile {
            transform: scaleX(var(--r));
            transition: transform .1s;
            z-index: 1;

            &.default {
                animation: none !important;
            }

            .cover {
                background-image: url('../assets/images/card-back-scale.png');
            }
        } 
        @keyframes card-float {
            // 0% {
            //     margin-top: 0%;
            // }
            // 50% {
            //     margin-top: -1%;
            // }
            // 100% {
            //     margin-top: 0%;
            // }
            0% {
                transform: translateY(-6%);
            }
            50% {
                transform: translateY(0%);
            }
            100% {
                transform: translateY(-6%);
            }
        }
        @keyframes card-active {
            0% {
                transform: scaleX(1) scale(1);
            }
            50% {
                transform: scaleX(0) scale(1.085);
            }
            100% {
                transform: scaleX(1) scale(1.17);
                z-index: 2;
            }
        }

        @keyframes card-default {
            // 0% {
            //     transform: scaleX(1) scale(1.17) translateY(25%);
            // }
            // 50% {
            //     transform: scaleX(0) scale(1.085) translateY(12.5%);
            // }
            // 100% {
            //     transform: scaleX(1) scale(1) translateY(0%);
            // }
            0% {
                transform: scaleX(1) scale(1.17);
            }
            50% {
                transform: scaleX(0) scale(1.085);
            }
            100% {
                transform: scaleX(1) scale(1);
            }
        }

        &.mobile {
            width: 300px;
            margin: auto;

            @include mobile {
                width: 80%;
            }
        }
    }

    @include pad {
        .card {
            width: 60vw;
            margin: auto;
        }
    }
}