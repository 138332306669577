#traffic {
    @include page-content;
    // margin-bottom: 12rem;

    .top-area {
        @include flex-center;
        align-items: flex-start;
        // margin-bottom: 58px;

        .left-area {
            width: 57%;
            margin-right: 2%;
            .content {
                @include content;
                
                &:last-of-type {
                    margin-top: 2rem;

                    ul {
                        margin: 0;
                    }
                }
            }
        }
        .right-area {
            width: 41%;
            aspect-ratio: 337/227;

            iframe {
                width: 100%;
                height: 100%;
                border: none;
            }
        }

        @include pad {
            padding: 0 8%;
            width: 84%;
            flex-direction: column;

            .left-area {
                width: 100%;
                margin-right: 0px;
                order: 1;
                margin-bottom: 2rem;
            }
            .right-area {
                width: 100%;
                order: 2;
            }
        }
    }
    .content {
        @include content;

        ul {
            li {
                @include font-style(0.875rem, $font-regular, $color-light-blue);
            }
        }
        p {
            @include font-style(0.875rem, $font-regular, $color-light-blue);
        }
        &-weather {
            margin-bottom: 68px;
            
            .weather-area {
                @include flex-center;
                border: 1px solid $color-yellow;
                border-radius: $radius;
                padding: 45px 0;
            }
        }
        &-stargazing {
            .stargazing-area {
                @include flex-center;
                border: 1px solid $color-yellow;
                border-radius: $radius;
                padding: 45px 0;
            }
        }
    }
}