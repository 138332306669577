.planets {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;

    .planet {
        position: absolute;
        aspect-ratio: 1;
        background-size: contain;
        background-repeat: no-repeat;

        &-1 {
            width: 18.5vw;
            top: 10%;
            right: 7vw;
            background-image: url('../assets/images/planet/planet-1.png')
        }
        &-2 {
            width: 10vw;
            top: 17%;
            left: 3vw;
            background-image: url('../assets/images/planet/planet-2.png')
        }
        &-3 {
            width: 13vw;
            top: 28%;
            left: 11vw;
            background-image: url('../assets/images/planet/planet-3.png')
        }
        &-4 {
            width: 16vw;
            top: 42%;
            right: 18vw;
            background-image: url('../assets/images/planet/planet-4.png')
        }
        &-5 {
            width: 29vw;
            top: 56%;
            right: -13vw;
            background-image: url('../assets/images/planet/planet-5.png')
        }

        @include pad {

            &-1 {
                width: 37.5vw;
                top: 13%;
                right: -10vw;
            }
            &-2 {
                width: 21vw;
                top: 23%;
                left: -7vw;
            }
            &-3 {
                width: 26vw;
                top: 35%;
                right: -9vw;
                left: unset;
            }
            &-4 {
                width: 32vw;
                top: 52%;
                right: -16vw;
            }
            &-5 {
                display: none;
                // width: 29vw;
                // top: 56%;
                // right: -13vw;
            }
        }
    }
}