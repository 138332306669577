.loading {
    @include flex-center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 5;

    img.loading-gif {
        width: 10%;
        max-width: 150px;
        min-width: 100px;
    }
}