.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -o-transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity= 40);
  z-index: 2;
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 32px;
  transition: all .25s ease-in;
  position: absolute;
  top: 20px;
}

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  content: "";
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  margin: 0 5px;
  display: inline-block;
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity= 0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel-root {
  outline: none;
}

.carousel {
  width: 100%;
  position: relative;
}

.carousel * {
  box-sizing: border-box;
}

.carousel img {
  width: 100%;
  pointer-events: none;
  display: inline-block;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  outline: 0;
  margin-top: -13px;
  font-size: 18px;
  top: 50%;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}

.carousel .thumbs {
  -o-transition: all .15s ease-in;
  white-space: nowrap;
  list-style: none;
  transition: all .15s ease-in;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.carousel .thumb {
  -o-transition: border .15s ease-in;
  white-space: nowrap;
  border: 3px solid #fff;
  margin-right: 6px;
  padding: 2px;
  transition: border .15s ease-in;
  display: inline-block;
  overflow: hidden;
}

.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #333;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  color: #fff;
  margin-top: 0;
  padding: 5px;
  font-size: 26px;
  top: 0;
  bottom: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #0003;
}

.carousel .slider-wrapper {
  width: 100%;
  -o-transition: height .15s ease-in;
  margin: auto;
  transition: height .15s ease-in;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.carousel .slider.animated {
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.carousel .slide {
  min-width: 100%;
  text-align: center;
  margin: 0;
  position: relative;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}

.carousel .slide iframe {
  width: calc(100% - 80px);
  border: 0;
  margin: 0 40px 40px;
  display: inline-block;
}

.carousel .slide .legend {
  -o-transition: all .5s ease-in-out;
  width: 90%;
  color: #fff;
  text-align: center;
  opacity: .25;
  transition: all .5s ease-in-out;
  -o-transition: opacity .35s ease-in-out;
  background: #000;
  border-radius: 10px;
  margin-left: -45%;
  padding: 10px;
  font-size: 12px;
  transition: opacity .35s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

.carousel .control-dots {
  text-align: center;
  width: 100%;
  z-index: 1;
  margin: 10px 0;
  padding: 0;
  position: absolute;
  bottom: 0;
}

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}

.carousel .control-dots .dot {
  -o-transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity= 30);
  width: 8px;
  height: 8px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  margin: 0 8px;
  transition: opacity .25s ease-in;
  display: inline-block;
  box-shadow: 1px 1px 2px #000000e6;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .carousel-status {
  text-shadow: 1px 1px 1px #000000e6;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.carousel:hover .slide .legend {
  opacity: 1;
}

html, body, #app {
  width: 100vw;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Noto Sans TC, sans-serif;
  font-size: 16px;
  position: relative;
  overflow-x: clip;
}

@media (max-width: 414px) {
  html, body, #app {
    font-size: 12px;
  }
}

@media (max-width: 900px) {
  html, body, #app {
    font-size: 14px;
  }
}

html .title, body .title, #app .title {
  color: #edba6d;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
}

html .title:after, html .title:before, body .title:after, body .title:before, #app .title:after, #app .title:before {
  content: "";
  width: 100%;
  border: 1px solid #edba6d;
  display: block;
}

html .title:before, body .title:before, #app .title:before {
  margin-right: 2.3rem;
}

html .title:after, body .title:after, #app .title:after {
  margin-left: 2.3rem;
}

@media (max-width: 900px) {
  html .title, body .title, #app .title {
    margin-bottom: 2rem;
  }
}

html .sub-title, body .sub-title, #app .sub-title {
  color: #caccf8;
  margin-bottom: 1rem;
  font-size: 1.75rem;
  font-weight: 600;
}

html .list-title, body .list-title, #app .list-title {
  color: #edba6d;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
}

html button, body button, #app button {
  cursor: pointer;
  background: none;
  border: none;
  font-family: Noto Sans TC, sans-serif;
}

html p, body p, #app p {
  min-height: 1.7rem;
  letter-spacing: .04rem;
  margin: 0;
  font-family: Noto Sans TC, sans-serif;
}

html :focus, body :focus, #app :focus {
  outline: none;
}

.block {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@keyframes test {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#info {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#info .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  #info .page-content {
    width: 100%;
    min-width: unset;
  }
}

#info .page-content {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#info .content {
  color: #fff;
  width: 100%;
  font-size: .875rem;
  font-weight: 400;
}

#info .content p {
  min-height: 1.7rem;
  letter-spacing: .04rem;
  margin: 0;
}

#info .content ul {
  padding: 0 1.3rem;
}

#info .content ul li {
  color: #caccf8;
  font-size: .875rem;
  font-weight: 400;
}

#info .content-session {
  width: 100%;
  margin-top: 5rem;
}

#info .content-session .sessions {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  margin-left: -1%;
  display: flex;
}

#info .content-session .sessions .session {
  aspect-ratio: 150 / 44;
  width: 18%;
  background-image: url("time-border.083eb74a.png");
  background-size: cover;
  justify-content: flex-start;
  align-items: center;
  margin: 1%;
  display: flex;
}

#info .content-session .sessions .session span {
  justify-content: center;
  align-items: center;
  font-weight: 400;
  display: flex;
}

#info .content-session .sessions .session span:first-child {
  width: 3rem;
  margin-left: 1rem;
  font-size: 1.125rem;
}

#info .content-session .sessions .session span:last-child {
  color: #edba6d;
  margin-left: .65rem;
  font-size: .75rem;
}

@media (max-width: 900px) {
  #info .content-session {
    padding: 0 8%;
  }

  #info .content-session .sessions .session {
    width: 40%;
    margin: 2%;
  }
}

#info .content-price {
  margin-bottom: 50px;
}

#info .content-price table {
  width: 100%;
  border-collapse: collapse;
}

#info .content-price table th:nth-of-type(1), #info .content-price table td:nth-of-type(1) {
  width: 15%;
}

#info .content-price table th:nth-of-type(2), #info .content-price table td:nth-of-type(2) {
  width: 50%;
}

#info .content-price table th:nth-of-type(3), #info .content-price table td:nth-of-type(3) {
  width: 35%;
}

#info .content-price table thead tr th {
  color: #191919;
  height: 33px;
  background-color: #edba6d;
  font-size: .875rem;
  font-weight: 400;
}

#info .content-price table thead tr th:first-child {
  border-radius: 8px 0 0;
  overflow: hidden;
}

#info .content-price table thead tr th:last-child {
  border-radius: 0 8px 0 0;
  overflow: hidden;
}

#info .content-price table tbody tr td {
  height: 62px;
  text-align: center;
  border: 1px solid #edba6d;
  font-size: 1.125rem;
  font-weight: 400;
}

#info .content-price table tbody tr:last-of-type td:first-child {
  border-radius: 0 0 0 8px;
}

@media (max-width: 900px) {
  #info .content-price {
    padding: 0 8%;
  }

  #info .content-price table {
    width: 84%;
  }

  #info .content-0 {
    width: 84%;
    padding: 0 8%;
  }
}

#info .content-1, #info .content-2 {
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  #info .content-1, #info .content-2 {
    width: 84%;
    padding: 0 8%;
  }
}

#intro {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  display: flex;
}

#intro .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  #intro .page-content {
    width: 100%;
    min-width: unset;
  }

  #intro {
    margin-bottom: 5rem;
  }
}

#intro .blank-1 {
  height: 75vh;
}

@media (max-width: 900px) {
  #intro .blank {
    height: 10vh;
  }
}

#intro .content {
  color: #fff;
  text-align: center;
  font-size: .875rem;
  font-weight: 400;
}

#intro .content p {
  min-height: 1.7rem;
  letter-spacing: .04rem;
  margin: 0;
}

#intro .content ul {
  padding: 0 1.3rem;
}

#intro .content ul li {
  font-size: .875rem;
}

#intro .content img.logo-lg {
  width: 20%;
  margin-bottom: 5.4rem;
}

@media (max-width: 900px) {
  #intro .content img.logo-lg {
    min-width: 30vw;
  }
}

#intro .content-intro {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#intro .content-intro p {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}

@media (max-width: 900px) {
  #intro .content-intro p {
    width: 90vw;
  }
}

#intro .content-video .video-area {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#intro .content-video .video-area img.video-cover {
  width: 50%;
  margin-right: 2rem;
}

#intro .content-video .video-area .video-intro .video-block {
  text-align: left;
  margin-bottom: 1rem;
}

#intro .content-video .video-area .video-intro .video-block .video-title {
  color: #a9845c;
  font-size: 1.125rem;
  font-weight: 600;
}

#intro .content-video .video-area .video-intro .video-block:nth-child(2) .video-title {
  margin-bottom: .3rem;
}

#intro .content-video .video-area .video-intro .video-block .video-name {
  color: #caccf8;
  font-size: 2rem;
  font-weight: 700;
}

#intro .content-video .video-area .video-intro .video-block .video-sub-name {
  color: #caccf8;
  font-size: 1.375rem;
  font-weight: 700;
}

#intro .content-video .video-area .video-intro .video-block .video-content p {
  color: #caccf8;
  font-size: 1rem;
  font-weight: 400;
}

#intro .content-video .video-area .video-intro .video-block button {
  color: #fff;
  background: #a9845c;
  border-radius: 1000px;
  margin-top: .5rem;
  padding: .45rem 5.7rem;
  font-size: 1.125rem;
  font-weight: 600;
}

#intro .content-video .popup {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #000c;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#intro .content-video .popup.show {
  display: block;
}

#intro .content-video .popup .page-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#intro .content-video .popup .page-content iframe {
  width: 100%;
  aspect-ratio: 5 / 3;
}

#intro .content-video .popup .page-content button.close-button {
  color: #fff;
  text-align: right;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 700;
}

@media (max-width: 900px) {
  #intro .content-video .video-area {
    flex-direction: column;
  }

  #intro .content-video .video-area img.video-cover {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0;
  }

  #intro .content-video .video-area .video-intro {
    width: 100%;
  }

  #intro .content-video .video-area .video-intro .video-block {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  #intro .content-video .video-area .video-intro .video-title {
    margin-bottom: .5rem;
  }

  #intro .content-video .video-area .video-intro .video-name {
    font-size: 1.5rem !important;
  }

  #intro .content-video .video-area .video-intro .video-sub-name {
    font-size: 1.05rem !important;
  }

  #intro .content-video .video-area .video-intro .video-content p {
    font-size: .875rem !important;
  }

  #intro .content-video .popup {
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: #0009;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
  }

  #intro .content-video .popup.show {
    display: block;
  }
}

#intro .content-picture {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  display: flex;
}

#intro .content-picture .carousel-root {
  margin-bottom: 10rem;
}

#intro .content-picture .carousel-root .carousel {
  overflow: unset;
}

#intro .content-picture .carousel-root .carousel ul.control-dots {
  bottom: -3rem;
}

#intro .content-picture .carousel-root .carousel ul.control-dots li.dot {
  width: .7vw;
  height: .7vw;
  min-width: .8rem;
  min-height: .8rem;
  opacity: 1;
  background: #caccf8;
  margin: 0 .75rem;
}

#intro .content-picture .carousel-root .carousel ul.control-dots li.dot.selected {
  background: #edba6d;
}

@media (max-width: 900px) {
  #intro .content-picture .carousel-root .carousel ul.control-dots {
    bottom: -2rem;
  }

  #intro .content-picture .carousel-root .carousel ul.control-dots li.dot {
    min-width: .7rem;
    min-height: .7rem;
    margin: 0 .5rem;
  }
}

#intro .content-picture .carousel-root .carousel button.control-arrow {
  opacity: .6;
  padding: 0;
  transition: all .3s;
}

#intro .content-picture .carousel-root .carousel button.control-arrow:hover {
  opacity: 1;
}

#intro .content-picture .carousel-root .carousel button.control-arrow:before {
  width: 5vw;
  height: 5vw;
  max-width: 2rem;
  max-height: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  margin: 0 1vw;
}

#intro .content-picture .carousel-root .carousel button.control-arrow.control-prev {
  right: 100%;
  left: unset;
}

#intro .content-picture .carousel-root .carousel button.control-arrow.control-prev:before {
  background-image: url("left-arrow.f4dbc2a9.png");
}

#intro .content-picture .carousel-root .carousel button.control-arrow.control-next {
  left: 100%;
  right: unset;
}

#intro .content-picture .carousel-root .carousel button.control-arrow.control-next:before {
  background-image: url("right-arrow.44bb71cc.png");
}

#intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider {
  padding: 0;
}

#intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide div .intro {
  width: 100%;
  height: 50%;
  text-align: left;
  opacity: 0;
  box-sizing: border-box;
  background: linear-gradient(357.54deg, #0b152f 2.01% 2.02%, #0b152fcc 35.26%, #22346500 78.01%);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 2rem 2.5rem;
  transition: opacity .5s .5s, transform .5s .3s;
  display: flex;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

#intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide div .intro .img-title {
  color: #caccf8;
  margin-bottom: .5rem;
  font-size: 1.75rem;
  font-weight: 400;
}

#intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide div .intro p.img-content {
  color: #caccf8;
  font-size: 1rem;
  font-weight: 400;
}

@media (max-width: 900px) {
  #intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide div .intro {
    height: 90%;
    padding: .75rem 1rem;
  }

  #intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide div .intro .img-title {
    color: #caccf8;
    font-size: 1.2rem;
    font-weight: 400;
  }

  #intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide div .intro p.img-content {
    color: #caccf8;
    font-size: .75rem;
    font-weight: 400;
  }
}

#intro .content-picture .carousel-root .carousel .slider-wrapper ul.slider li.slide.selected div .intro {
  opacity: 1;
  transform: translateX(0%);
}

#intro .content-picture .start-finding {
  aspect-ratio: 800 / 290;
  width: 100%;
  position: relative;
  overflow: hidden;
}

#intro .content-picture .start-finding img.mirror {
  width: 100%;
}

#intro .content-picture .start-finding .intro {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(269.1deg, #0b152f 21.1%, #0b152fcc 62.13%, #0b152f00 99.06%);
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 2rem 2.5rem;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

#intro .content-picture .start-finding .intro .img-title {
  color: #caccf8;
  margin-bottom: .5rem;
  font-size: 1.75rem;
  font-weight: 400;
}

#intro .content-picture .start-finding .intro p.img-content {
  color: #caccf8;
  font-size: 1rem;
  font-weight: 400;
}

@media (max-width: 900px) {
  #intro .content-picture .start-finding .intro {
    width: 100%;
    padding: .75rem 1rem;
  }

  #intro .content-picture .start-finding .intro .img-title {
    color: #caccf8;
    font-size: 1.2rem;
    font-weight: 400;
  }

  #intro .content-picture .start-finding .intro p.img-content {
    color: #caccf8;
    font-size: .75rem;
    font-weight: 400;
  }
}

#intro .content-card {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  display: flex;
  position: relative;
}

#intro .content-card p {
  color: #caccf8;
  width: 110%;
  font-size: .9rem;
  font-weight: 400;
}

@media (max-width: 900px) {
  #intro .content {
    box-sizing: border-box;
    padding: 0 8%;
  }
}

#traffic {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#traffic .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  #traffic .page-content {
    width: 100%;
    min-width: unset;
  }
}

#traffic .top-area {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#traffic .top-area .left-area {
  width: 57%;
  margin-right: 2%;
}

#traffic .top-area .left-area .content {
  color: #fff;
  font-size: .875rem;
  font-weight: 400;
}

#traffic .top-area .left-area .content p {
  min-height: 1.7rem;
  letter-spacing: .04rem;
  margin: 0;
}

#traffic .top-area .left-area .content ul {
  padding: 0 1.3rem;
}

#traffic .top-area .left-area .content ul li {
  font-size: .875rem;
}

#traffic .top-area .left-area .content:last-of-type {
  margin-top: 2rem;
}

#traffic .top-area .left-area .content:last-of-type ul {
  margin: 0;
}

#traffic .top-area .right-area {
  width: 41%;
  aspect-ratio: 337 / 227;
}

#traffic .top-area .right-area iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 900px) {
  #traffic .top-area {
    width: 84%;
    flex-direction: column;
    padding: 0 8%;
  }

  #traffic .top-area .left-area {
    width: 100%;
    order: 1;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  #traffic .top-area .right-area {
    width: 100%;
    order: 2;
  }
}

#traffic .content {
  color: #fff;
  font-size: .875rem;
  font-weight: 400;
}

#traffic .content p {
  min-height: 1.7rem;
  letter-spacing: .04rem;
  margin: 0;
}

#traffic .content ul {
  padding: 0 1.3rem;
}

#traffic .content ul li, #traffic .content p {
  color: #caccf8;
  font-size: .875rem;
  font-weight: 400;
}

#traffic .content-weather {
  margin-bottom: 68px;
}

#traffic .content-weather .weather-area, #traffic .content-stargazing .stargazing-area {
  border: 1px solid #edba6d;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 45px 0;
  display: flex;
}

.blank {
  height: 75vh;
}

@media (max-width: 900px) {
  .blank {
    height: 10vh;
  }
}

#souvenir {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  display: flex;
}

#souvenir .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  #souvenir .page-content {
    width: 100%;
    min-width: unset;
  }
}

#souvenir .card-area {
  width: 68%;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: relative;
}

#souvenir .card-area img.card {
  width: 100%;
}

#souvenir .card-area form {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1.5%;
  font-weight: 400;
  display: flex;
  position: absolute;
}

#souvenir .card-area form input {
  width: 9.5rem;
  color: #edba6d;
  text-align: center;
  background: none;
  border: #0000;
  border-bottom: 1px solid #edba6d;
  border-radius: 0;
  padding-bottom: .5rem;
  font-size: 1.125rem;
}

#souvenir .card-area form input::placeholder {
  color: #edba6d;
}

#souvenir .card-area form input:focus-visible, #souvenir .card-area form input:focus {
  outline: none;
}

@media (max-width: 900px) {
  #souvenir .card-area form input {
    width: 42%;
    padding-bottom: .2rem;
    font-size: .75rem;
  }
}

#souvenir .card-area form button {
  margin-top: -.4rem;
}

#souvenir .card-area form button img {
  width: 1.2rem;
}

@media (max-width: 900px) {
  #souvenir .card-area form button {
    margin-top: 0;
    padding: 0;
  }

  #souvenir .card-area form button img {
    width: 1rem;
  }

  #souvenir .title {
    width: 84%;
    padding: 0 8%;
  }
}

#banner {
  aspect-ratio: 1280 / 627;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

#banner .background {
  width: 100vw;
}

#banner iframe {
  width: 100%;
  height: 100%;
  transform: scale(1.2);
}

#banner .content {
  color: #fff;
  font-size: .875rem;
  font-weight: 400;
  position: absolute;
  bottom: 10%;
  left: 18%;
}

#banner .content p {
  min-height: 1.7rem;
  letter-spacing: .04rem;
  margin: 0;
}

#banner .content ul {
  padding: 0 1.3rem;
}

#banner .content ul li {
  font-size: .875rem;
}

.loading {
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: #000000d9;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

.loading img.loading-gif {
  width: 10%;
  max-width: 150px;
  min-width: 100px;
}

.background {
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.background:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #0003, #0003), linear-gradient(#19191900 0%, #000 97.4%);
  position: absolute;
}

.background video.bg {
  min-height: 100vh;
  transition: filter 1s;
}

.background video.bg.dark {
  filter: brightness(.3);
}

@media (max-width: 414px) {
  .background video.bg {
    height: 100vh;
  }
}

.background img.line {
  width: 150%;
  opacity: 0;
  z-index: 1;
  transition: opacity .3s, transform 3s;
  position: absolute;
  transform: translateX(-25%)translateY(-50%)rotate(-15deg)scaleY(1);
}

@media (max-width: 900px) {
  .background img.line {
    display: none;
  }
}

.background img.line-1 {
  opacity: 1;
  transform: translateX(10%)translateY(30%)rotate(20deg);
}

.background img.line-2 {
  opacity: 1;
  transform: translateX(10%)translateY(0%)rotate(0)scaleY(2);
}

.planets {
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
}

.planets .planet {
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.planets .planet-1 {
  width: 18.5vw;
  background-image: url("planet-1.f927b9dc.png");
  top: 10%;
  right: 7vw;
}

.planets .planet-2 {
  width: 10vw;
  background-image: url("planet-2.c488b1c5.png");
  top: 17%;
  left: 3vw;
}

.planets .planet-3 {
  width: 13vw;
  background-image: url("planet-3.d28bd6d1.png");
  top: 28%;
  left: 11vw;
}

.planets .planet-4 {
  width: 16vw;
  background-image: url("planet-4.09b3318f.png");
  top: 42%;
  right: 18vw;
}

.planets .planet-5 {
  width: 29vw;
  background-image: url("planet-5.26f9bb44.png");
  top: 56%;
  right: -13vw;
}

@media (max-width: 900px) {
  .planets .planet-1 {
    width: 37.5vw;
    top: 13%;
    right: -10vw;
  }

  .planets .planet-2 {
    width: 21vw;
    top: 23%;
    left: -7vw;
  }

  .planets .planet-3 {
    width: 26vw;
    top: 35%;
    right: -9vw;
    left: unset;
  }

  .planets .planet-4 {
    width: 32vw;
    top: 52%;
    right: -16vw;
  }

  .planets .planet-5 {
    display: none;
  }
}

.Cards {
  width: 80vw;
  height: 315px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
  display: flex;
}

@media (max-width: 900px) {
  .Cards {
    height: 350px;
  }
}

.Cards .card {
  aspect-ratio: 139 / 198;
  width: 14%;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  justify-content: center;
  align-items: center;
  margin: 0 2%;
  transition: transform .3s;
  display: flex;
  position: relative;
}

.Cards .card .cover {
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-image: url("card-back.f64bf6af.png");
  background-size: cover;
  border-radius: 8px;
  transition: background-image 0s linear .15s;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 0 50px #525496;
}

.Cards .card css-doodle {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.Cards .card:hover {
  z-index: 5;
  transform: scale(1.1)translateY(5%);
}

.Cards .card.back {
  width: 12%;
  margin: 0 5.2% -10%;
}

.Cards .card:nth-of-type(1) {
  margin: 0 5.2% -10% 10.4%;
}

.Cards .card:nth-of-type(4) {
  margin: 0 10.4% -10% 5.2%;
}

.Cards .card:nth-of-type(5) {
  margin: 0 2% 0 0;
}

.Cards .card:nth-of-type(9) {
  margin: 0 0 0 2%;
}

.Cards .card.active {
  animation: 5s infinite card-float, .3s forwards card-active;
}

.Cards .card.active.card-1 .cover {
  background-image: url("1.ddfd6c36.png") !important;
}

.Cards .card.active.card-2 .cover {
  background-image: url("2.5a6451d6.png") !important;
}

.Cards .card.active.card-3 .cover {
  background-image: url("3.31e47d9f.png") !important;
}

.Cards .card.active.card-4 .cover {
  background-image: url("4.d9a675c4.png") !important;
}

.Cards .card.active.card-5 .cover {
  background-image: url("5.5f3f0079.png") !important;
}

.Cards .card.active.card-6 .cover {
  background-image: url("6.5676c246.png") !important;
}

.Cards .card.active.card-7 .cover {
  background-image: url("7.89ea3b61.png") !important;
}

.Cards .card.active.card-8 .cover {
  background-image: url("8.0818e89b.png") !important;
}

.Cards .card.active.card-9 .cover {
  background-image: url("9.ee83d429.png") !important;
}

.Cards .card.default .cover {
  background-image: url("card-back.f64bf6af.png");
}

.Cards .card.default.card-1 {
  animation: 5s -1s infinite card-float, .3s card-default;
}

.Cards .card.default.card-2 {
  animation: 5s -2.5s infinite card-float, .3s card-default;
}

.Cards .card.default.card-3 {
  animation: 5s -4.5s infinite card-float, .3s card-default;
}

.Cards .card.default.card-4 {
  animation: 5s -4s infinite card-float, .3s card-default;
}

.Cards .card.default.card-5 {
  animation: 5s -2s infinite card-float, .3s card-default;
}

.Cards .card.default.card-6 {
  animation: 5s -1.5s infinite card-float, .3s card-default;
}

.Cards .card.default.card-7 {
  animation: 5s -3.5s infinite card-float, .3s card-default;
}

.Cards .card.default.card-8 {
  animation: 5s -.5s infinite card-float, .3s card-default;
}

.Cards .card.default.card-9 {
  animation: 5s -3s infinite card-float, .3s card-default;
}

.Cards .card.random, .Cards .card.mobile {
  transform: scaleX(var(--r));
  z-index: 1;
  transition: transform .1s;
}

.Cards .card.random.default, .Cards .card.mobile.default {
  animation: none !important;
}

.Cards .card.random .cover, .Cards .card.mobile .cover {
  background-image: url("card-back-scale.d598ca2c.png");
}

@keyframes card-float {
  0% {
    transform: translateY(-6%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-6%);
  }
}

@keyframes card-active {
  0% {
    transform: scaleX(1)scale(1);
  }

  50% {
    transform: scaleX(0)scale(1.085);
  }

  100% {
    z-index: 2;
    transform: scaleX(1)scale(1.17);
  }
}

@keyframes card-default {
  0% {
    transform: scaleX(1)scale(1.17);
  }

  50% {
    transform: scaleX(0)scale(1.085);
  }

  100% {
    transform: scaleX(1)scale(1);
  }
}

.Cards .card.mobile {
  width: 300px;
  margin: auto;
}

@media (max-width: 414px) {
  .Cards .card.mobile {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .Cards .card {
    width: 60vw;
    margin: auto;
  }
}

.Footer {
  width: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 0 5rem;
  display: flex;
  position: absolute;
}

.Footer .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .Footer .page-content {
    width: 100%;
    min-width: unset;
  }
}

.Footer:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: .6;
  background-color: #222245;
  position: absolute;
  top: 0;
  left: 0;
}

.Footer .content {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  display: flex;
}

.Footer .content img.logo-lg {
  width: 12%;
  margin-right: 5rem;
}

.Footer .content .list-area {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Footer .content .list-area ul {
  height: 9rem;
  border-left: 1px solid #a9845c;
  padding: 0 3rem;
  list-style: none;
}

.Footer .content .list-area ul:nth-of-type(1) {
  border-left: none;
}

.Footer .content .list-area ul li {
  color: #caccf8;
  height: 1.5rem;
  font-size: .875rem;
  font-weight: 400;
}

.Footer .content .list-area ul li a, .Footer .content .list-area ul li button {
  color: #caccf8;
  padding: 0;
  font-size: .875rem;
  font-weight: 400;
  text-decoration: none;
}

@media (max-width: 900px) {
  .Footer .content {
    flex-direction: column;
  }

  .Footer .content img.logo-lg {
    width: 20%;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .Footer .content .list-area {
    flex-direction: column;
  }

  .Footer .content .list-area ul {
    text-align: center;
    height: unset;
    width: 100%;
    border-bottom: 1px solid #a9845c;
    border-left: none;
    margin: 0;
    padding: 1rem 0;
  }

  .Footer .content .list-area ul:nth-last-of-type(1) {
    border-bottom: none;
  }
}

.Navbar {
  width: 100%;
  height: 100px;
  width: 100vw;
  z-index: 3;
  background: linear-gradient(#000c 50%, #0000);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

.Navbar .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .Navbar .page-content {
    width: 100%;
    min-width: unset;
  }
}

.Navbar .page-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Navbar .logo-sm {
  width: 23%;
  transition: transform .5s;
  transform: translateY(-200%);
}

.Navbar .logo-sm button {
  width: 100%;
  color: #fff;
}

.Navbar .logo-sm button img {
  width: 100%;
}

.Navbar .logo-sm.show {
  transform: translateY(0%);
}

.Navbar .nav-item button, .Navbar .nav-item a {
  color: #fff;
  cursor: pointer;
  padding: 0 12px;
  font-size: .875rem;
  font-weight: 400;
}

.Navbar .nav-item button a, .Navbar .nav-item a a {
  color: #fff;
  padding: 0;
  text-decoration: none;
}

@media (max-width: 900px) {
  .Navbar {
    height: unset;
  }
}

.Navbar .mobile {
  width: 100%;
  position: relative;
}

.Navbar .mobile button.toggle {
  width: 2.3rem;
  height: 2.3rem;
  z-index: 1;
  padding: 0;
  position: absolute;
  top: calc(7vw + .7rem);
  right: 7vw;
}

.Navbar .mobile button.toggle .lines {
  width: 100%;
  height: 2px;
  border-radius: 1000px;
  transition: background-color .3s;
  background-color: #fff !important;
}

.Navbar .mobile button.toggle .lines:before, .Navbar .mobile button.toggle .lines:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 1000px;
  transition: transform .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.Navbar .mobile button.toggle .lines:before {
  transform: translateX(-50%)translateY(calc(.7rem - 50%));
}

.Navbar .mobile button.toggle .lines:after {
  transform: translateX(-50%)translateY(calc(-50% - .7rem));
}

.Navbar .mobile .nav-item {
  width: 100%;
  height: 0;
  background: none;
  padding: 0;
  transition: padding .5s, height .5s;
  position: relative;
  overflow: hidden;
}

.Navbar .mobile .nav-item button {
  width: 100vw;
  height: 15vw;
  text-align: left;
  color: #caccf8;
  padding-left: 3rem;
  font-size: 1.15rem;
  transition: height .2s;
  overflow: hidden;
}

.Navbar .mobile .nav-item button a {
  color: #caccf8;
  text-align: left;
  padding: 0;
  font-size: 1.15rem;
  font-weight: 400;
}

.Navbar .mobile .nav-item button img.logo {
  height: 2.25rem;
}

.Navbar.mobile {
  mix-blend-mode: unset;
  align-items: flex-start;
}

.Navbar.toggle button.toggle .lines {
  background-color: #0000 !important;
}

.Navbar.toggle button.toggle .lines:before {
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.Navbar.toggle button.toggle .lines:after {
  transform: translateX(-50%)translateY(-50%)rotate(-45deg);
}

.Navbar.toggle .nav-item {
  height: 100vh;
  background: linear-gradient(#000 50%, #0000);
  padding: 15vw 0;
}

#personal {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#personal .page-content {
  width: 63%;
  height: 100%;
  min-width: 800px;
  margin: 0 auto;
}

@media (max-width: 900px) {
  #personal .page-content {
    width: 100%;
    min-width: unset;
  }
}

#personal .page-content {
  min-height: calc(100vh - 12rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#personal .page-content .date {
  color: #edba6d;
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 400;
}

#personal .page-content .sub-title {
  color: #caccf8;
  margin-top: 7rem;
  font-size: 1.25rem;
  font-weight: 600;
}

#personal .page-content .title-1 {
  margin-top: 10rem;
  animation: 1s 1s forwards title-hide;
}

#personal .page-content .title-2, #personal .page-content .title-3, #personal .page-content .title-4 {
  margin-top: 7rem;
}

#personal .page-content .title-4 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media (max-width: 900px) {
  #personal .page-content .title {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
  }
}

#personal .page-content .card-area {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#personal .page-content .card-area img.card-back {
  width: 32%;
  border-radius: 8px;
  overflow: hidden;
}

#personal .page-content .card-area img.stars {
  max-width: 45%;
  max-height: 80%;
  opacity: 0;
  animation: 1s 2s forwards star-show;
  position: absolute;
}

#personal .page-content .card-area img.icon {
  max-width: 45%;
  max-height: 80%;
  opacity: 0;
  transition: opacity 1s;
  animation: 3s linear infinite icon-breathe;
  position: absolute;
  top: 55%;
}

#personal .page-content .card-area img.icon.show {
  opacity: 1;
}

@keyframes icon-breathe {
  0% {
    filter: brightness(.8);
  }

  50% {
    filter: brightness(1.2);
  }

  100% {
    filter: brightness(.8);
  }
}

@keyframes title-hide {
  100% {
    opacity: 0;
  }
}

@keyframes card-opacity {
  100% {
    opacity: .5;
  }
}

@keyframes star-show {
  100% {
    opacity: 1;
  }
}

#personal .page-content p {
  color: #caccf8;
  width: 70%;
  min-height: 2rem;
  text-align: center;
  letter-spacing: .05rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

#personal .page-content p.second {
  margin-top: 1rem;
}

#personal .page-content img.scene {
  width: 60%;
  margin-top: 2rem;
}

#personal .page-content img.mythical-beast {
  width: 100%;
}

#personal .page-content img.astrolabe {
  width: 75%;
  border-radius: 100%;
  margin-bottom: 5rem;
  box-shadow: 0 0 2rem .5rem #000;
}

@media (max-width: 900px) {
  #personal .page-content .card-area img.card-back {
    width: 60%;
  }

  #personal .page-content .card-area img.icon {
    max-width: 60%;
    top: 65%;
  }

  #personal .page-content img.mythical-beast {
    width: 85%;
  }
}

#personal.not-found .page-content {
  padding: 12rem 0;
}

#personal.not-found .content-none {
  width: 40%;
  aspect-ratio: 1;
  z-index: 1;
  background: radial-gradient(50% 50%, #161c52 0%, #22346500 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

#personal.not-found .content-none .title-none {
  color: #caccf8;
  margin-bottom: 0;
  font-size: 2.5rem;
  font-weight: 500;
}

#personal.not-found .content-none .sub-title-none {
  color: #caccf8;
  font-size: 1rem;
  font-weight: 400;
}

#personal.not-found .content-none .third-title-none {
  color: #caccf8;
  font-size: 1.25rem;
  font-weight: 400;
}

#personal.not-found img.card-back {
  width: 50%;
  opacity: .5;
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 900px) {
  #personal.not-found .content-none {
    width: 80%;
  }
}

/*# sourceMappingURL=entry.68a39e51.css.map */
