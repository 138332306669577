#intro {
    @include flex-center;
    @include page-content;
    flex-direction: column;
    margin-bottom: 10rem;

    @include pad {
        margin-bottom: 5rem;
    }

    .blank {
        &-1 {
            height: calc(100vh * 0.75);
        }

        @include pad {
            height: calc(100vh * 0.1);
        }
    }
    .content {
        @include content;
        text-align: center;

        img.logo-lg {
            width: 20%;
            margin-bottom: 5.4rem;
            
            @include pad {
                min-width: 30vw;
            }
        }
        &-intro {
            @include flex-center;
            flex-direction: column;
            height: 100vh;

            p {
                @include font-style(1rem, $font-regular, $color-white);
                @include pad {
                    width: 90vw;
                }
            }
        }
        &-video {
            // margin-bottom: 40rem;

            .video-area {
                @include flex-center;
                align-items: flex-start;
                width: 100%;

                img.video-cover {
                    width: 50%;
                    margin-right: 2rem;
                }
                .video-intro {
                    .video-block {
                        text-align: left;
                        margin-bottom: 1rem;

                        .video-title {
                            @include font-style(1.125rem, $font-semi-bold, $color-gold);

                        }
                        &:nth-child(2){
                            .video-title {
                                margin-bottom: 0.3rem;
                            }
                        }
                        .video-name {
                            @include font-style(2rem, $font-bold, $color-light-blue);
                        }
                        .video-sub-name {
                            @include font-style(1.375rem, $font-bold, $color-light-blue);
                        }
                        .video-content {
                            p {
                                @include font-style(1rem, $font-regular, $color-light-blue);
                            }
                        }
                        button {
                            @include font-style(1.125rem, $font-semi-bold, $color-white);
                            background: $color-gold;
                            border-radius: 1000px;
                            padding: 0.45rem 5.7rem;
                            margin-top: 0.5rem;

                        }
                    }
                }
            }
            .popup {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.8);
                z-index: 100;
                display: none;

                &.show {
                    display: block;
                }


                .page-content {
                    @include flex-center;
                    flex-direction: column;
                    position: relative;

                    iframe {
                        width: 100%;
                        aspect-ratio: 5/3;
                    }
                    button.close-button {
                        @include font-style(1.25rem, $font-bold, $color-white);
                        text-align: right;
                        width: 100%;
                    }
                }
            }
            
            @include pad {
                .video-area {
                    flex-direction: column;
    
                    img.video-cover {
                        width: 100%;
                        margin-right: 0rem;
                        margin-bottom: 1rem;
                    }
                    .video-intro {
                        width: 100%;
                        
                        .video-block {
                            text-align: center;
                            margin-bottom: 1.5rem;
                        }
                        .video-title {
                            margin-bottom: 0.5rem;
                        }
                        .video-name {
                            font-size: 1.5rem !important;
                        }
                        .video-sub-name {
                            font-size: 1.05rem !important;
                        }
                        .video-content {
                            p {
                                font-size: 0.875rem !important;
                            }
                        }
                    }
                }
                .popup {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.6);
                    z-index: 100;
                    display: none;
    
                    &.show {
                        display: block;
                    }
                }
            }
        }
        &-picture {
            @include flex-center;
            flex-direction: column;
            margin-bottom: 5rem;

            .carousel-root {
                margin-bottom: 10rem;

                .carousel {
                    overflow: unset;

                    ul.control-dots {
                        bottom: -3rem;

                        li.dot {
                            width: 0.7vw;
                            height: 0.7vw;
                            min-width: 0.8rem;
                            min-height: 0.8rem;
                            margin: 0 0.75rem;
                            opacity: 1;
                            background: $color-light-blue;

                            &.selected {
                                background: $color-yellow;
                            }
                        }

                        @include pad {
                            bottom: -2rem;
                            
                            li.dot {
                                min-width: 0.7rem;
                                min-height: 0.7rem;
                                margin: 0 0.5rem;
                            }
                        }
                    }
                    button.control-arrow {
                        padding: 0;
                        opacity: .6;
                        transition: .3s;

                        &:hover {
                            opacity: 1;
                        }

                        &::before {
                            width: 5vw;
                            height: 5vw;
                            max-width: 2rem;
                            max-height: 2rem;
                            margin: 0 1vw;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            border: none;
                        }

                        &.control-prev {
                            right: 100%;
                            left: unset;

                            &::before {
                                background-image: url('../assets/images/left-arrow.png');
                            }
                        }
                        &.control-next {
                            left: 100%;
                            right: unset;
                            &::before {
                                background-image: url('../assets/images/right-arrow.png');
                            }
                        }
                    }
                    .slider-wrapper {
                        ul.slider {
                            padding: 0rem;

                            li.slide {
                                div {
                                    .intro {
                                        @include flex-center;
                                        flex-direction: column;
                                        position: absolute;
                                        justify-content: flex-end;
                                        align-items: flex-start;
                                        padding: 2rem 2.5rem;
                                        bottom: 0;
                                        width: 100%;
                                        height: 50%;
                                        text-align: left;
                                        background: linear-gradient(357.54deg, #0B152F 2.01%, #0B152F 2.02%, rgba(11, 21, 47, 0.8) 35.26%, rgba(34, 52, 101, 0) 78.01%);
                                        opacity: 0;
                                        box-sizing: border-box;
                                        transform: translateY(100%);
                                        transition: opacity .5s .5s, transform .5s .3s;


                                        .img-title {
                                            @include font-style(1.75rem, $font-regular, $color-light-blue);
                                            margin-bottom: 0.5rem;
                                        }
                                        p.img-content {
                                            @include font-style(1rem, $font-regular, $color-light-blue);
                                        }

                                        @include pad {
                                            padding: 0.75rem 1rem;
                                            height: 90%;
                                            
                                            .img-title {
                                                @include font-style(1.2rem, $font-regular, $color-light-blue);
                                            }
                                            p.img-content {
                                                @include font-style(0.75rem, $font-regular, $color-light-blue);
                                            }
                                        }
                                    }
                                }

                                &.selected {
                                    div {
                                        .intro {
                                            transform: translateX(0%);
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .start-finding {
                position: relative;
                aspect-ratio: 800/290;
                width: 100%;
                overflow: hidden;
                // margin-bottom: 4.5rem;

                img.mirror {
                    width: 100%;
                    // opacity: .5;
                }
                .intro {
                    @include flex-center;
                    flex-direction: column;
                    position: absolute;
                    justify-content: center;
                    align-items: flex-end;
                    padding: 2rem 2.5rem;
                    bottom: 0;
                    right: 0;
                    width: 50%;
                    height: 100%;
                    background: linear-gradient(269.1deg, #0B152F 21.1%, rgba(11, 21, 47, 0.8) 62.13%, rgba(11, 21, 47, 0) 99.06%);
                    box-sizing: border-box;

                    .img-title {
                        @include font-style(1.75rem, $font-regular, $color-light-blue);
                        margin-bottom: 0.5rem;
                    }
                    p.img-content {
                        @include font-style(1rem, $font-regular, $color-light-blue);
                    }
                    @include pad {
                        padding: 0.75rem 1rem;
                        width: 100%;
                        
                        .img-title {
                            @include font-style(1.2rem, $font-regular, $color-light-blue);
                        }
                        p.img-content {
                            @include font-style(0.75rem, $font-regular, $color-light-blue);
                        }
                    }
                }
            }
        }
        &-card {
            @include flex-center;
            flex-direction: column;
            position: relative;
            margin-bottom: 3rem;

            // .title {
            //     @include font-style(1.75rem, $font-regular, $color-light-blue);
            //     position: relative;
            //     width: 50%;
            //     margin-bottom: 1.5rem;
            //     z-index: 1;

            //     &::after {
            //         content: '';
            //         position: absolute;
            //         bottom: -20%;
            //         left: 0;
            //         width: 100%;
            //         height: 70%;
            //         background: radial-gradient(50% 50% at 50% 50%, rgba(82, 84, 150, 0.75) 0%, rgba(146, 158, 187, 0) 100%);
            //         z-index: -1;
            //     }
            // }
            p {
                @include font-style(0.9rem, $font-regular, $color-light-blue);
                width: 110%;
            }
        }
        @include pad {
            padding: 0 8%;
            box-sizing: border-box;
        }
    }
}