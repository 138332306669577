#info {
    @include page-content;

    .page-content {
        @include flex-center;
        flex-direction: column;
        align-items: flex-start;
        // margin-top: 30rem;
    }
    .content {
        @include content;
        width: 100%;

        ul {
            li {
                @include font-style(0.875rem, $font-regular, $color-light-blue);
            }
        }
        &-session {
            width: 100%;
            margin-top: 5rem;
            
            .sessions {
                @include flex-center;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 100%;
                margin-left: -1%;
                margin-bottom: 50px;

                .session {
                    @include flex-center;
                    justify-content: flex-start;
                    background-image: url('../assets//images/time-border.png');
                    background-size: cover;
                    aspect-ratio: 150/44;
                    width: 18%;
                    margin: 1%;

                    span {
                        @include flex-center;
                        font-weight: $font-regular;

                        &:first-child {
                            font-size: 1.125rem;
                            width: 3rem;
                            margin-left: 1rem;
                        }
                        &:last-child {
                            font-size: 0.75rem;
                            color: $color-yellow;
                            margin-left: 0.65rem
                        }
                    }
                }
            }
            @include pad {
                padding: 0 8%;

                .sessions {
                    .session {
                        width: 40%;
                        margin: 2%;
                    }
                }
            }
        }
        &-price {
            margin-bottom: 50px;
            
            table {
                width: 100%;
                border-collapse: collapse;
                
                th, td {
                    &:nth-of-type(1) {
                        width: 15%;
                    }
                    &:nth-of-type(2) {
                        width: 50%;
                    }
                    &:nth-of-type(3) {
                        width: 35%;
                    }
                }
                thead {
                    tr {
                        th {
                            background-color: $color-yellow;
                            color: $color-dark-gray;
                            font-size: 0.875rem;
                            font-weight: $font-regular;
                            height: 33px;

                            &:first-child {
                                border-radius: $radius 0 0 0;
                                overflow: hidden;
                            }
                            &:last-child {
                                border-radius: 0 $radius 0 0;
                                overflow: hidden;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            border: 1px solid $color-yellow;
                            font-size: 1.125rem;
                            font-weight: $font-regular;
                            height: 62px;
                            text-align: center;
                        }
                        &:last-of-type {
                            td {
                                &:first-child {
                                    border-radius: 0 0 0 $radius;
                                }
                            }
                        }
                    }
                }
            }
            @include pad {
                padding: 0 8%;

                table {
                    width: 84%;
                }
            }
        }
        &-0 {
            @include pad {
                padding: 0 8%;
                width: 84%;
            }
        }
        &-1, &-2 {
            margin-bottom: 1rem;

            @include pad {
                padding: 0 8%;
                width: 84%;
            }
        }
    }
}