.Footer {
    @include flex-center;
    @include page-content;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    padding: 3rem 0 5rem;
    box-sizing: border-box;
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: $color-dark-blue;
        opacity: .6;
    }
    .content {
        @include flex-center;
        width: 100%;
        font-size: 1.125rem;

        img.logo-lg {
            width: 12%;
            margin-right: 5rem;
        }
        .list-area {
            @include flex-center;

            ul {
                padding-left: 0rem;
                list-style: none;
                border-left: 1px solid $color-gold;
                padding: 0 3rem;
                height: 9rem;

                &:nth-of-type(1) {
                    border-left: none;
                }

                li {
                    @include font-style(0.875rem, $font-regular, $color-light-blue);
                    height: 1.5rem;
                    
                    a, button {
                        padding: 0;
                        @include font-style(0.875rem, $font-regular, $color-light-blue);
                        text-decoration: none;
                    }
                }
            }
        }
        @include pad {
            flex-direction: column;
            
            img.logo-lg {
                width: 20%;
                margin-right: 0rem;
                margin-bottom: 2rem;
            }
            .list-area {
                flex-direction: column;
    
                ul {
                    border-bottom: 1px solid $color-gold;
                    border-left: none;
                    text-align: center;
                    height: unset;
                    width: 100%;
                    padding: 1rem 0;
                    margin: 0;
    
                    &:nth-last-of-type(1) {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}