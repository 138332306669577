#banner {
    // test
    @include flex-center;
    aspect-ratio: 1280/627;
    height: 100vh;
    overflow: hidden;

    .background {
        width: 100vw;
        // position: absolute;
    }
    iframe {
        // display: none;
        width: 100%;
        height: 100%;
        transform: scale(1.2)
    }
    .content {
        @include content;
        position: absolute;
        left: 18%;
        bottom: 10%;
    }
}